import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { image, text } from '@pdfme/schemas';
import { generate } from '@pdfme/generator';
import AirBadgeModal, {
  MODAL_SIZE_LARGE,
  MODAL_SIZE_MEDIUM,
} from '../../../../shared/components/AirBadgeModal/AirBadgeModal';
import WebcamCaptureModal from './WebcamCaptureModal';

import MSO_escortStampBase64 from './data/MSO_Airport/escort_stamp_base64';
import MSO_limitedDrivingStampBase64 from './data/MSO_Airport/limited_driving_stamp_base64';
import MSO_movementDrivingStampBase64 from './data/MSO_Airport/movement_driving_stamp_base64';

import ERI_escortStampBase64 from './data/ERI_Airport/escort_stamp_base64';
import ERI_drivingStampBase64 from './data/ERI_Airport/driving_stamp_base64';

import GPT_noEscortStampBase64 from './data/GPT_Airport/no_escort_stamp_base64';

import { AppContext, colors } from '../../../../App';
import ReadOnlyFormData from '../../../../shared/components/ReadOnlyFormData/ReadOnlyFormData';
import FontAwesomeIcon from '../../../../shared/components/FontAwesome/FontAwesomeIcon';
import StaggeredDatePicker from '../../../../shared/components/DatePickers/StaggeredDatePicker';
import DrivingEndorsementPicker from '../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/DrivingEndorsementPicker';
import EscortEndorsementPicker from '../../BadgeApplications/BadgeApplicationEndorsementsPicker/components/EscortEndorsementPicker';
import { getDrivingOptions } from '../../../../shared/BadgeTypes/drivingOptionsUtils';

function FormRow({ column1, column2 }) {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }} className="mr-4">
        {column1}
      </div>
      <div style={{ width: 80 }}>{column2}</div>
    </div>
  );
}

function FontSizeInput({ value, onChange }) {
  return (
    <Input
      type="number"
      min={1}
      max={80}
      value={value}
      onFocus={event => {
        const fontSize = event?.target?.value;
        if (Number(fontSize) === 0) {
          onChange('');
        }
      }}
      onBlur={event => {
        const fontSize = event?.target?.value;
        if (Number(fontSize) === 0) {
          onChange(0);
        }
      }}
      onChange={event => {
        const fontSize = event?.target?.value;
        const decimalRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
        if (!fontSize || Number(fontSize) < 0) {
          onChange(0);
        } else if (decimalRegex.test(fontSize)) {
          onChange(Number(fontSize));
        }
      }}
      style={{ width: 80 }}
    />
  );
}

const getExpirationDateDisplayValue = ({ expiration, badgeType }) => {
  const shouldUseTwoDigitYear = badgeType?.config?.printing?.shouldUseTwoDigitYear || false;
  const shouldDisplayDayInDates = badgeType?.config?.printing?.shouldDisplayDayInDates || false;
  if (expiration?.indexOf('-') > -1) {
    let [year, month, day] = expiration.split('-');
    if (shouldUseTwoDigitYear) {
      year = year.toString().substring(2);
    }
    if (!shouldDisplayDayInDates) {
      return `${month}/${year}`;
    } else {
      return `${month}/${day}/${year}`;
    }
  }
  return '';
};

function ExpirationEditor({ expiration, onChange, badgeType }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const [modalValue, setModalValue] = useState(null);
  const [hasInvalidDate, setHasInvalidDate] = useState(false);

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setDisplayValue(getExpirationDateDisplayValue({ expiration, badgeType }));
  }, [expiration]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Input defaultValue={displayValue} className="form-control custom-disabled" />
        <button
          className="btn btn-link"
          style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer' }}
          onClick={() => {
            setHasInvalidDate(true);
            setModalValue(expiration);
            setIsModalOpen(true);
          }}
        >
          change
        </button>
      </div>

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title="Change Expiration Date"
        isOpen={isModalOpen}
        onClose={onModalClose}
        saveLabel="Apply Change"
        onSave={() => {
          onChange(modalValue);
          setIsModalOpen(false);
        }}
        saveDisabled={hasInvalidDate}
      >
        {/* NOTE: VALUE NEEDS TO BE IN YYYY-MM-DD format!!!! */}
        <StaggeredDatePicker
          startingYear={new Date().getFullYear()}
          endingYear={new Date().getFullYear() + 5}
          defaultValue={expiration}
          onValidDate={({ year, month, day }) => {
            setModalValue(`${year}-${month}-${day}`);
            setHasInvalidDate(false);
          }}
          onInvalidDate={() => setHasInvalidDate(true)}
        />
      </AirBadgeModal>
    </>
  );
}

const getDrivingOption = ({ badgeType, driving }) => {
  const drivingOptions = getDrivingOptions(badgeType?.config?.endorsements?.driving);
  return drivingOptions.find(option => option.value === driving) || 'ERROR';
};

let updateTimeout = null;

/**
 *
 * https://pdfme.com/docs/getting-started
 *
 */
export default function PrintBadgeModal({
  inline,
  isOpen,
  onClose,
  badgeType,
  badgeData,
  isFreeformMode,
  readonlyEndorsements,
}) {
  const app = useContext(AppContext);
  const airportCode = app?.airport?.abbreviation;
  const [template, setTemplate] = useState({});
  const [number, setNumber] = useState('');
  const [expiration, setExpiration] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [fullName, setFullName] = useState('');
  const [hair, setHair] = useState('');
  const [dob, setDob] = useState('');
  const [eyes, setEyes] = useState('');
  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [numberFontSize, setNumberFontSize] = useState(0);
  const [expirationFontSize, setExpirationFontSize] = useState(0);
  const [firstNameFontSize, setFirstNameFontSize] = useState(0);
  const [lastNameFontSize, setLastNameFontSize] = useState(0);
  const [fullNameFontSize, setFullNameFontSize] = useState(0);
  const [hairFontSize, setHairFontSize] = useState(0);
  const [dobFontSize, setDobFontSize] = useState(0);
  const [eyesFontSize, setEyesFontSize] = useState(0);
  const [weightFontSize, setWeightFontSize] = useState(0);
  const [heightFontSize, setHeightFontSize] = useState(0);
  const [companyNameFontSize, setCompanyNameFontSize] = useState(0);
  const [hasEscort, setHasEscort] = useState(false);
  const [drivingOption, setDrivingOption] = useState(null);
  const [isPictureModalOpen, setIsPictureModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isPrinted, setIsPrinted] = useState(null);
  const [cardNumber, setCardNumber] = useState('');
  const [isMisprint, setIsMisprint] = useState(false);
  const [hasEndorsements, setHasEndorsements] = useState(false);
  const [isAlreadyRendered, setIsAlreadyRendered] = useState(false);

  const reset = () => {
    setTemplate({});
    setNumber('');
    setExpiration('');
    setFirstName('');
    setFullName('');
    setHair('');
    setDob('');
    setEyes('');
    setWeight('');
    setHeight('');
    setLastName('');
    setCompanyName('');
    setNumberFontSize(0);
    setExpirationFontSize(0);
    setFirstNameFontSize(0);
    setLastNameFontSize(0);
    setFullNameFontSize(0);
    setHairFontSize(0);
    setDobFontSize(0);
    setEyesFontSize(0);
    setWeightFontSize(0);
    setHeightFontSize(0);
    setCompanyNameFontSize(0);
    setHasEscort(false);
    setDrivingOption(null);
    setIsPrinted(null);
    setCardNumber('');
    setIsMisprint(false);
    setHasEndorsements(false);
    setIsAlreadyRendered(false);
  };

  const isFinalizeDisabled = () => {
    if (!template.schemas || !template.schemas[0]) {
      return true;
    }

    const schema = template.schemas[0];

    if (schema.fullName && !fullName) {
      return true;
    }

    if (schema.firstName && !firstName) {
      return true;
    }

    if (schema.lastName && !lastName) {
      return true;
    }

    if (schema.number && !number) {
      return true;
    }

    if (schema.expiration && !expiration) {
      return true;
    }

    if (schema.hair && !hair) {
      return true;
    }

    if (schema.dob && !dob) {
      return true;
    }

    if (schema.eyes && !eyes) {
      return true;
    }

    if (schema.weight && !weight) {
      return true;
    }

    if (schema.height && !height) {
      return true;
    }

    return !!(schema.companyName && !companyName);
  };

  const isCompletePrintingSaveDisabled = () => {
    if (isMisprint) {
      return !cardNumber;
    }
    return !cardNumber || isPrinted !== true;
  };

  const onCloseConfirmModal = () => {
    setIsPrinted(null);
    if (!badgeType?.isBadgeNumberAndSerialNumberSame) {
      setCardNumber('');
    }
    setIsConfirmModalOpen(false);
  };

  const onCompletePrinting = () => {
    setIsConfirmModalOpen(false);
    reset();

    let cardSerialNumber = cardNumber;
    if (badgeType?.isBadgeNumberAndSerialNumberSame) {
      cardSerialNumber = number;
    }

    onClose({
      number,
      cardNumber: cardSerialNumber,
      expiration,
      isEscort: hasEscort,
      driving: drivingOption?.value,
      template,
      isMisprint: false,
    });
  };

  const onRecordMisprint = () => {
    setIsConfirmModalOpen(false);

    let cardSerialNumber = cardNumber;
    if (badgeType?.isBadgeNumberAndSerialNumberSame) {
      cardSerialNumber = number;
    }

    onClose({
      number,
      cardNumber: cardSerialNumber,
      expiration,
      isEscort: hasEscort,
      driving: drivingOption?.value,
      template,
      isMisprint: true,
    });
    if (!badgeType?.isBadgeNumberAndSerialNumberSame) {
      setCardNumber('');
    }
  };

  const onCloseModal = () => {
    reset();
    onClose();
  };

  const onPrint = async template => {
    const pdf = await generate({ template, plugins: { text, image }, inputs: template.sampledata });
    const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
    document.getElementById('finalized').src = URL.createObjectURL(blob);
  };

  const onClosePictureModal = args => {
    setIsPictureModalOpen(false);
    if (args?.imageData) {
      template.sampledata[0].faceImage = args?.imageData;
      setTemplate(template);
      onPrint(template);
    }
  };

  const onFontChange = property => newValue => {
    updateViewer(property, newValue);
  };

  const updateViewer = (property, newValue) => {
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }

    const updatedTemplate = { ...template };
    const sampleData = template.sampledata[0];
    const schema = template.schemas[0];
    switch (property) {
      case 'number': {
        if (hasEndorsements) {
          if (['MSO', 'ERI'].indexOf(airportCode) > -1) {
            sampleData.number = newValue.split('').join('\n');
          } else {
            sampleData.number = newValue;
          }
        } else {
          sampleData.number = newValue;
        }
        setNumber(newValue);
        if (badgeType?.isBadgeNumberAndSerialNumberSame) {
          setCardNumber(newValue);
        }
        break;
      }
      case 'expiration': {
        sampleData.expiration = getExpirationDateDisplayValue({ expiration: newValue, badgeType });
        setExpiration(newValue);
        break;
      }
      case 'fullName': {
        sampleData.fullName = newValue;
        setFullName(newValue);
        break;
      }
      case 'firstName': {
        sampleData.firstName = newValue;
        setFirstName(newValue);
        break;
      }
      case 'lastName': {
        sampleData.lastName = newValue;
        setLastName(newValue);
        break;
      }
      case 'companyName': {
        sampleData.companyName = newValue;
        setCompanyName(newValue);
        break;
      }
      case 'hair': {
        sampleData.hair = newValue;
        setHair(newValue);
        break;
      }
      case 'dob': {
        sampleData.dob = newValue;
        setDob(newValue);
        break;
      }
      case 'eyes': {
        sampleData.eyes = newValue;
        setEyes(newValue);
        break;
      }
      case 'weight': {
        sampleData.weight = newValue;
        setWeight(newValue);
        break;
      }
      case 'height': {
        sampleData.height = newValue;
        setHeight(newValue);
        break;
      }

      case 'numberFontSize': {
        schema.number.fontSize = Number(newValue);
        setNumberFontSize(newValue);
        break;
      }
      case 'expirationFontSize': {
        schema.expiration.fontSize = Number(newValue);
        setExpirationFontSize(newValue);
        break;
      }
      case 'fullNameFontSize': {
        schema.fullName.fontSize = Number(newValue);
        setFullNameFontSize(newValue);
        break;
      }
      case 'firstNameFontSize': {
        schema.firstName.fontSize = Number(newValue);
        setFirstNameFontSize(newValue);
        break;
      }
      case 'lastNameFontSize': {
        schema.lastName.fontSize = Number(newValue);
        setLastNameFontSize(newValue);
        break;
      }
      case 'companyNameFontSize': {
        schema.companyName.fontSize = Number(newValue);
        setCompanyNameFontSize(newValue);
        break;
      }
      case 'hairFontSize': {
        schema.hair.fontSize = Number(newValue);
        setHairFontSize(newValue);
        break;
      }
      case 'dobFontSize': {
        schema.dob.fontSize = Number(newValue);
        setDobFontSize(newValue);
        break;
      }
      case 'eyesFontSize': {
        schema.eyes.fontSize = Number(newValue);
        setEyesFontSize(newValue);
        break;
      }
      case 'weightFontSize': {
        schema.weight.fontSize = Number(newValue);
        setWeightFontSize(newValue);
        break;
      }
      case 'heightFontSize': {
        schema.height.fontSize = Number(newValue);
        setHeightFontSize(newValue);
        break;
      }

      case 'escort': {
        if (newValue === true) {
          if (airportCode === 'ERI') {
            sampleData.escort = ERI_escortStampBase64;
          } else if (airportCode === 'MSO') {
            sampleData.escort = MSO_escortStampBase64;
          } else {
            // Fallback for local development
            sampleData.escort = MSO_escortStampBase64;
          }
        } else if (airportCode === 'GPT') {
          sampleData.escort = GPT_noEscortStampBase64;
        } else {
          delete sampleData.escort;
        }
        break;
      }
      case 'driving': {
        if (airportCode === 'GPT') {
          if (newValue === 'limited') {
            sampleData.driving = 'II';
          } else if (newValue === 'movement_areas') {
            sampleData.driving = 'I';
          } else if (newValue === 'driving_4') {
            sampleData.driving = 'RTA';
          } else if (newValue === 'driving_5') {
            sampleData.driving = 'T';
          } else {
            delete sampleData.driving;
          }
        } else {
          if (newValue !== 'none') {
            sampleData.driving = ERI_drivingStampBase64;
          } else {
            delete sampleData.driving;
          }
        }
        break;
      }
      case 'limitedDriving': {
        if (newValue === true) {
          sampleData.limitedDriving = MSO_limitedDrivingStampBase64;
        } else {
          delete sampleData.limitedDriving;
        }
        break;
      }
      case 'movementDriving': {
        if (newValue === true) {
          sampleData.movementDriving = MSO_movementDrivingStampBase64;
        } else {
          delete sampleData.movementDriving;
        }
        break;
      }
    }
    setTemplate(updatedTemplate);

    updateTimeout = setTimeout(() => {
      onPrint(template);
    }, 500);
  };

  const buildViewer = () => {
    if (isAlreadyRendered) {
      // Prevent unnecessary re-renders when push notifications are received (no idea why this happens)
      return;
    }

    const hasEscort = !!badgeData.endorsements?.isEscort;
    setHasEscort(hasEscort);

    const drivingEndorsement = badgeData.endorsements?.driving || 'none';
    const drivingOptions = getDrivingOptions(drivingEndorsement);
    const drivingOption = drivingOptions.find(option => option.value === drivingEndorsement);
    setDrivingOption(drivingOption);

    setExpiration(badgeData.expiration);
    setNumber(badgeData.number);
    setFirstName(badgeData.firstName);
    setLastName(badgeData.lastName);
    setFullName(badgeData.fullName);
    setHair(badgeData.hair);
    setDob(badgeData.dob);
    setEyes(badgeData.eyes);
    setWeight(badgeData.weight);
    setHeight(badgeData.height);
    setCompanyName(badgeData.companyName);

    if (badgeType?.isBadgeNumberAndSerialNumberSame) {
      setCardNumber(badgeData.number);
    }

    let number = badgeData.number;
    if (['MSO', 'ERI'].indexOf(airportCode) > -1) {
      number = badgeData.number.split('').join('\n');
    }

    const data = {
      number,
      firstName: badgeData.firstName || '',
      lastName: badgeData.lastName || '',
      fullName: badgeData.fullName || '',
      hair: badgeData.hair || '',
      dob: badgeData.dob || '',
      eyes: badgeData.eyes || '',
      weight: badgeData.weight || '',
      height: badgeData.height || '',
      companyName: badgeData.companyName || '',
      expiration: getExpirationDateDisplayValue({ expiration: badgeData.expiration, badgeType }),
    };

    if (hasEscort) {
      if (airportCode === 'ERI') {
        data.escort = ERI_escortStampBase64;
      } else if (airportCode === 'MSO') {
        data.escort = MSO_escortStampBase64;
      } else {
        // Fallback for local development
        data.escort = MSO_escortStampBase64;
      }
    } else if (airportCode === 'GPT') {
      data.escort = GPT_noEscortStampBase64;
    }
    if (drivingEndorsement === 'limited') {
      data.limitedDriving = MSO_limitedDrivingStampBase64;
    }
    if (drivingEndorsement === 'movement_areas') {
      data.movementDriving = MSO_movementDrivingStampBase64;
    }

    const newTemplate = badgeType.defaultPrintSchema || {};
    const hasEndorsements =
      !!newTemplate.schemas[0]?.escort ||
      !!newTemplate.schemas[0]?.limitedDriving ||
      !!newTemplate.schemas[0]?.movementDriving;
    setHasEndorsements(hasEndorsements);
    if (!hasEndorsements) {
      // No need to newline separators
      data.number = badgeData.number;
    }
    newTemplate.sampledata = [{ ...data }];

    setTemplate(newTemplate);
    setNumberFontSize(newTemplate.schemas[0]?.number?.fontSize || 0);
    setExpirationFontSize(newTemplate.schemas[0]?.expiration?.fontSize || 0);
    setFirstNameFontSize(newTemplate.schemas[0]?.firstName?.fontSize || 0);
    setLastNameFontSize(newTemplate.schemas[0]?.lastName?.fontSize || 0);
    setFullNameFontSize(newTemplate.schemas[0]?.fullName?.fontSize || 0);
    setHairFontSize(newTemplate.schemas[0]?.hair?.fontSize || 0);
    setDobFontSize(newTemplate.schemas[0]?.dob?.fontSize || 0);
    setEyesFontSize(newTemplate.schemas[0]?.eyes?.fontSize || 0);
    setWeightFontSize(newTemplate.schemas[0]?.weight?.fontSize || 0);
    setHeightFontSize(newTemplate.schemas[0]?.height?.fontSize || 0);
    setCompanyNameFontSize(newTemplate.schemas[0]?.companyName?.fontSize || 0);
    onPrint(newTemplate);

    setIsAlreadyRendered(true);
  };

  const onProceed = () => {
    setIsMisprint(false);
    setIsConfirmModalOpen(true);
  };

  const onRecordMisprintClicked = () => {
    setIsMisprint(true);
    setIsConfirmModalOpen(true);
  };

  const setDrivingSelection = option => {
    setDrivingOption(option);

    const selection = option.value;

    if (template?.schemas[0]?.driving) {
      updateViewer('driving', selection);
      return;
    }

    if (selection === 'none') {
      updateViewer('limitedDriving', false);
      updateViewer('movementDriving', false);
    } else if (selection === 'limited') {
      updateViewer('limitedDriving', true);
      updateViewer('movementDriving', false);
    } else if (selection === 'movement_areas') {
      updateViewer('movementDriving', true);
      if (airportCode === 'MSO') {
        updateViewer('limitedDriving', true);
      } else {
        updateViewer('limitedDriving', false);
      }
    } else if (selection === 'driving_4') {
      updateViewer('limitedDriving', false);
      updateViewer('movementDriving', false);
      updateViewer('driving_4', true);
      updateViewer('driving_5', false);
    } else if (selection === 'driving_5') {
      updateViewer('limitedDriving', false);
      updateViewer('movementDriving', false);
      updateViewer('driving_4', false);
      updateViewer('driving_5', true);
    }
  };

  useEffect(() => {
    if (
      (isOpen || inline) &&
      badgeType &&
      badgeData &&
      MSO_escortStampBase64 &&
      MSO_limitedDrivingStampBase64 &&
      MSO_movementDrivingStampBase64 &&
      ERI_escortStampBase64 &&
      ERI_drivingStampBase64
    ) {
      buildViewer();
    }
  }, [
    inline,
    isOpen,
    badgeType,
    badgeData,
    MSO_escortStampBase64,
    MSO_limitedDrivingStampBase64,
    MSO_movementDrivingStampBase64,
    ERI_escortStampBase64,
    ERI_drivingStampBase64,
  ]);

  return (
    <AirBadgeModal
      inline={inline}
      size={MODAL_SIZE_LARGE}
      title={`Print ${badgeType?.displayName || ''} Badge`}
      isOpen={isOpen}
      onClose={onCloseModal}
      saveLabel="Finalize Printed Badge"
      onSave={onProceed}
      saveDisabled={isFinalizeDisabled()}
      middleExtra={
        <Button color="warning" onClick={onRecordMisprintClicked} disabled={isFinalizeDisabled()}>
          Record Misprinted Badge
        </Button>
      }
    >
      <Alert color="info">
        When you are ready to print or download the badge template, use the on-screen controls provided by your browser.
        You can find the controls in the presentation area below. You may need to hover your mouse over the badge image
        to see the controls.
      </Alert>
      {!isFreeformMode && (
        <Row
          style={{
            border: '1px solid #c8ced3',
            backgroundColor: '#f0f0f0',
            borderRadius: 5,
            padding: 12,
            margin: '0 0 32px 0',
          }}
        >
          <Col>
            <Row>
              <Col md={12} lg={6}>
                <ReadOnlyFormData
                  skipTextTransform
                  label="Badge Holder"
                  value={`${badgeData?.firstName || '_____'} ${badgeData?.lastName || '_____'}`}
                />
              </Col>
              <Col md={6} lg={3}>
                <ReadOnlyFormData
                  skipTextTransform
                  label="Airport Person ID"
                  value={
                    badgeData?.airportPersonId ? (
                      <div style={{ display: 'inline-block' }}>{badgeData?.airportPersonId}</div>
                    ) : (
                      <div style={{ display: 'inline-block' }}>
                        <FontAwesomeIcon
                          className="fa fa-ban"
                          color={colors.danger}
                          style={{ fontSize: '1rem', paddingLeft: 0 }}
                        />{' '}
                        Not associated
                      </div>
                    )
                  }
                />
              </Col>
              <Col md={6} lg={3}>
                <ReadOnlyFormData skipTextTransform label="Date Of Birth" value={badgeData?.dateOfBirth || '-'} />
              </Col>
            </Row>

            {hasEndorsements && readonlyEndorsements && (
              <>
                <div className="separator mt-3 mb-3">Endorsements</div>

                <Grid container spacing={3}>
                  <DrivingEndorsementPicker
                    isReadOnly={true}
                    displayAsGridItem={true}
                    sm={12}
                    md={6}
                    context={{
                      drivingEndorsementConfig: badgeType?.config?.endorsements?.driving,
                      drivingSelection: getDrivingOption({ badgeType, driving: badgeData?.endorsements?.driving }),
                      setDrivingSelection: () => false,
                      shouldSkipDefaultSelection: true,
                      data: { badgeType },
                    }}
                  />
                  <EscortEndorsementPicker
                    isReadOnly={true}
                    displayAsGridItem={true}
                    sm={12}
                    md={6}
                    context={{
                      escortEndorsementConfig: badgeType?.config?.endorsements?.escort,
                      escortSelection: hasEscort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 },
                      setEscortSelection: () => false,
                      shouldSkipDefaultSelection: true,
                      data: { badgeType },
                    }}
                  />
                </Grid>
              </>
            )}
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <iframe id="finalized" style={{ height: 600, width: 430 }}></iframe>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
            <Button size="lg" color="primary" onClick={() => setIsPictureModalOpen(true)}>
              <i className="fa-solid fa-camera mr-2"></i>Take Picture
            </Button>
          </div>
        </Col>
        <Col>
          {template.schemas && template.schemas[0]?.fullName && (
            <>
              <div className="separator mt-2 mb-2">FULL NAME</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={fullName} onChange={event => updateViewer('fullName', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={fullNameFontSize} onChange={onFontChange('fullNameFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          {template.schemas && template.schemas[0]?.number && (
            <>
              <div className="separator mb-2">NUMBER</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={number} onChange={event => updateViewer('number', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={numberFontSize} onChange={onFontChange('numberFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          {template.schemas && template.schemas[0]?.hair && (
            <>
              <div className="separator mt-2 mb-2">HAIR COLOR</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={hair} onChange={event => updateViewer('hair', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={hairFontSize} onChange={onFontChange('hairFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          {template.schemas && template.schemas[0]?.dob && (
            <>
              <div className="separator mt-2 mb-2">DATE OF BIRTH</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={dob} onChange={event => updateViewer('dob', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={dobFontSize} onChange={onFontChange('dobFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          {template.schemas && template.schemas[0]?.eyes && (
            <>
              <div className="separator mt-2 mb-2">EYE COLOR</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={eyes} onChange={event => updateViewer('eyes', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={eyesFontSize} onChange={onFontChange('eyesFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          {template.schemas && template.schemas[0]?.weight && (
            <>
              <div className="separator mt-2 mb-2">BODY WEIGHT</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={weight} onChange={event => updateViewer('weight', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={weightFontSize} onChange={onFontChange('weightFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          {template.schemas && template.schemas[0]?.height && (
            <>
              <div className="separator mt-2 mb-2">BODY HEIGHT</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={height} onChange={event => updateViewer('height', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={heightFontSize} onChange={onFontChange('heightFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          <div className="separator mt-2 mb-2">EXPIRATION</div>
          <FormRow
            column1={
              <FormGroup>
                <Label className="required">Value</Label>
                <ExpirationEditor
                  badgeType={badgeType}
                  expiration={expiration}
                  onChange={expiration => updateViewer('expiration', expiration)}
                />
              </FormGroup>
            }
            column2={
              <FormGroup>
                <Label className="required">Font Size</Label>
                <FontSizeInput value={expirationFontSize} onChange={onFontChange('expirationFontSize')} />
              </FormGroup>
            }
          />

          {template.schemas && template.schemas[0]?.firstName && (
            <>
              <div className="separator mt-2 mb-2">FIRST NAME</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={firstName} onChange={event => updateViewer('firstName', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={firstNameFontSize} onChange={onFontChange('firstNameFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          {template.schemas && template.schemas[0]?.lastName && (
            <>
              <div className="separator mt-2 mb-2">LAST NAME</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={lastName} onChange={event => updateViewer('lastName', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={lastNameFontSize} onChange={onFontChange('lastNameFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          {template.schemas && template.schemas[0]?.companyName && (
            <>
              <div className="separator mt-2 mb-2">COMPANY NAME</div>
              <FormRow
                column1={
                  <FormGroup>
                    <Label className="required">Value</Label>
                    <Input value={companyName} onChange={event => updateViewer('companyName', event.target.value)} />
                  </FormGroup>
                }
                column2={
                  <FormGroup>
                    <Label className="required">Font Size</Label>
                    <FontSizeInput value={companyNameFontSize} onChange={onFontChange('companyNameFontSize')} />
                  </FormGroup>
                }
              />
            </>
          )}

          {hasEndorsements && !readonlyEndorsements && (
            <>
              <div className="separator" style={{ marginTop: 12, marginBottom: 16 }}>
                ENDORSEMENTS
              </div>
              <div>
                <EscortEndorsementPicker
                  context={{
                    escortEndorsementConfig: badgeType?.config?.endorsements?.escort,
                    escortSelection: hasEscort ? { label: 'yes', value: 1 } : { label: 'no', value: 0 },
                    setEscortSelection: selection => {
                      if (selection?.value === 1) {
                        setHasEscort(true);
                        updateViewer('escort', true);
                      } else {
                        setHasEscort(false);
                        updateViewer('escort', false);
                      }
                    },
                    shouldSkipDefaultSelection: true,
                    data: { badgeType },
                  }}
                />
              </div>
              <div>
                <DrivingEndorsementPicker
                  context={{
                    drivingEndorsementConfig: badgeType?.config?.endorsements?.driving,
                    drivingSelection: drivingOption,
                    setDrivingSelection,
                    shouldSkipDefaultSelection: true,
                    data: { badgeType },
                  }}
                />
              </div>
            </>
          )}
        </Col>
      </Row>

      <WebcamCaptureModal isOpen={isPictureModalOpen} onClose={onClosePictureModal} badgeTemplate={template} />

      <AirBadgeModal
        size={MODAL_SIZE_MEDIUM}
        title={isMisprint ? 'Record Misprinted Badge' : 'Finalize Badge Issuance'}
        isOpen={isConfirmModalOpen}
        onClose={onCloseConfirmModal}
        saveLabel={isMisprint ? 'Record Misprint' : 'Issue Badge'}
        saveColor={isMisprint ? 'warning' : 'primary'}
        onSave={isMisprint ? onRecordMisprint : onCompletePrinting}
        saveDisabled={isCompletePrintingSaveDisabled()}
      >
        {!isMisprint && (
          <Row>
            <Col>
              <Label className="required">Was the badge correctly printed?</Label>
              <RadioGroup
                row
                aria-label="Did it print?"
                name="isPrinted"
                value={`${isPrinted}`}
                onChange={event => setIsPrinted(event.target.value === 'true')}
              >
                <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
              </RadioGroup>
            </Col>
          </Row>
        )}
        {isPrinted === false && (
          <Alert color="warning">
            If the badge did not print correctly, you will need to go back and click the
            <strong>
              <i>&nbsp;Record Misprinted Badge&nbsp;</i>
            </strong>
            button.
          </Alert>
        )}
        <Row className="mt-2">
          <Col>
            <FormGroup>
              <Label className="required">
                What is the card (serial) number on the {isMisprint ? 'misprinted' : 'printed'} badge?
              </Label>
              <Input
                className={badgeType?.isBadgeNumberAndSerialNumberSame ? 'form-control custom-disabled' : ''}
                value={cardNumber}
                onChange={event => setCardNumber(event.target.value)}
              />
            </FormGroup>
          </Col>
        </Row>
      </AirBadgeModal>

      <div style={{ marginTop: 8 }}>&nbsp;</div>
    </AirBadgeModal>
  );
}
